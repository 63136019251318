import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import featureImg5 from '../../../assets/images/banner/banner-group5.png'
import featureImg6 from '../../../assets/images/banner/banner-group2.png'

const Home3Feature2 = () => {
    return (
        <>
            <section className="sc-feature-section-area4 darker-bg sc-pb-55 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image shake-y">
                                    <img className="feature-image" src={featureImg5} alt="aboutImage" />
                                    <img className="feature-image_1 pulse" src={featureImg6} alt="About" />
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color text-uppercase">Produkt</span>
                                        <h2 className="heading-title sc-mb-25">Automatisierte Bonitätsanalyse
</h2>
                                        <div className="description sc-mb-25">Mehrdimensionale automatisierte Bonitätsanalysen auf Basis individueller Parameter und diverser Datenquellen.</div>
                                    </div>
                                    <div className="feature_check sc-mb-40">
                                        <ul>
                                            <li>
                                                <i className="ri-checkbox-circle-fill"></i>Daten-Konnektoren
                                            </li>
                                            <li>
                                                <i className="ri-checkbox-circle-fill"></i>KPI-basierte OCR Dokumenten-Analyse
                                            </li>
                                            <li>
                                                <i className="ri-checkbox-circle-fill"></i>KPI-Builder und XBRL-Check
                                            </li>
                                            <li>
                                                <i className="ri-checkbox-circle-fill"></i>Individuelle Riskoprofilbögen
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="banner-btn-area">
                                    <Link className="third-btn" to="#"><span>Mehr erfahren</span></Link>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <br/>
                <br/>
            </section>
        </>
    );
};

export default Home3Feature2;