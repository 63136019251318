import React from 'react';
import { Fade } from 'react-reveal';
import CountUp from 'react-countup';

const Home3Counter = () => {
    return (
        <>
            <section className="sc-counter-area style-two sc-pt-60 sc-pb-60">
                <div className="container position-relative">
                    <div className="row ">
                        <Fade bottom delay={300}>
                            <div className="col" data-aos="slide-up" data-aos-duration="800" data-aos-delay="300">
                                <div className="sc-counter-box sc-md-mb-25 text-center">
                                    {/* <div className="sc-count"><span><CountUp end={2} duration={1} />,<CountUp end={5} duration={3} /></span> Mrd. EUR </div> */}
                                    <h4 className="count-title white-color">Mit Compeon haben wir in über 10 Jahren die führende Digitale Plattform für KMU-Finanzierungen entwickelt.</h4>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom delay={400}>
                            <div className="col" data-aos="slide-up" data-aos-duration="800" data-aos-delay="400" >
                                <div className="sc-counter-box sc-md-mb-25 text-center">
                                    {/* <div className="sc-count">&gt;<span><CountUp end={30} duration={2} /></span>.000 Anfragen p.a.</div> */}
                                    <h4 className="count-title white-color">Mit Compeon Connect profitieren Sie nun in ihren eigenen Prozessen von unserer Erfahrung, unseren Tools und unseren Abläufen. </h4>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom delay={500}>
                            <div className="col" data-aos="slide-up" data-aos-duration="800" data-aos-delay="500">
                                <div className="sc-counter-box counter-border text-center">
                                    {/* <div className="sc-count"><span><CountUp end={60} duration={3} /></span>.000+</div> */}
                                    <h4 className="count-title white-color">Digitalisieren und automatisieren Sie Ihre Prozesse nun mit Compeon Connect und reduzieren Sie Ihre Prozesszeiten und Prozesskosten</h4>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home3Counter;