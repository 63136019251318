import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import featureImg3 from '../../../assets/images/banner/banner-group.png'
import featureImg4 from '../../../assets/images/banner/banner-group3.png'

const Home3Feature = () => {
    return (
        <>
            <section className="sc-feature-section-area3 darker-bg sc-pb-65 sc-md-pb-10">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade bottom>
                            <div className="col-lg-6">
                                <div className="sc-feature-item sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                    <div className="sc-heading-area sc-mb-30">
                                        <span className="sc-sub-title primary-color text-uppercase">Produkt</span>
                                        <h2 className="heading-title sc-mb-20">Digitaler Angebotsrechner</h2>
                                        <div className="description sc-mb-20">
                                        Online Echtzeitkalkulation von Eigen- und Fremdprodukten für Kunden und/oder Berater inkl. Ausplatzierungsmöglichkeit von Anfragen als Ventillösungen.
                                        </div>
                                    </div>
                                    <div className="feature_check sc-mb-40">
                                            <ul>
                                                <li>
                                                    <i className="ri-checkbox-circle-fill"></i>White-Label-Frontends
                                                </li>
                                                <li>
                                                    <i className="ri-checkbox-circle-fill"></i>Echtzeit-Kalkulation und -Simulation
                                                </li>
                                                <li>
                                                    <i className="ri-checkbox-circle-fill"></i>Ausplatzierungsfunktion und Ventillösung
                                                </li>
                                            </ul>
                                    </div>
                                    <div className="banner-btn-area">
                                        <Link className="third-btn" to="#"><span>Mehr erfahren</span></Link>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="col-lg-6">
                                <div className="feature-image-area shake-y">
                                    <img className="feature-img" src={featureImg3} alt="Feature" />
                                    <img className="feature-img_1 pulse" src={featureImg4} alt="Feature" />
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <br/>
                <br/>
            </section>
        </>
    );
};

export default Home3Feature;