import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const Home3Services = () => {
    return (
        <>
            <section className="sc-service-section-area3 darker-bg sc-pt-130 sc-md-pt-70 sc-pb-100 sc-md-pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center sc-mb-55">
                                <span className="sc-sub-title primary-color text-uppercase">Ihr Nutzen</span>
                                <h3 className="heading-title">Unsere Infrastruktur bietet Banken diverse Vorteile und ein neuartiges Operational Model im Firmenkundengeschäft</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Fade bottom delay={100}>
                            <div className="col">
                                <div className="sc-service-content-box text-center sc-md-mb-30">
                                    <div className="service-icon">
                                        <svg className="sc-mb-25" width="55" height="56" viewBox="0 0 65 66" fill="primary-color" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M42.3453 34.9336C41.7393 34.9336 41.2477 35.4248 41.2477 36.0312C41.2477 38.1098 39.8712 39.8001 38.18 39.8001L25.9607 39.8005C24.2685 39.8005 22.893 38.1093 22.893 36.0317L22.8925 21.754C22.8925 19.6755 24.2685 17.9843 25.9602 17.9843H38.1795C39.8717 17.9843 41.2472 19.6755 41.2472 21.754C41.2472 22.361 41.7389 22.8517 42.3449 22.8517C42.9509 22.8517 43.4425 22.3605 43.4425 21.754C43.4425 18.4644 41.0809 15.7886 38.1791 15.7886H33.168V11.9494C33.168 11.3425 32.6768 10.8518 32.0703 10.8518C31.4634 10.8518 30.9727 11.343 30.9727 11.9494V15.7886H25.9612C23.0588 15.7886 20.6978 18.4644 20.6978 21.754V36.0305C20.6978 39.3192 23.0588 41.995 25.9612 41.995H30.9727V45.8342C30.9727 46.4402 31.4639 46.9318 32.0703 46.9318C32.6773 46.9318 33.168 46.4402 33.168 45.8342V41.995H38.1805C41.0828 41.995 43.4439 39.3192 43.4439 36.0305C43.4439 35.424 42.9523 34.9329 42.3463 34.9329L42.3453 34.9336Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M16.16 52.547C15.9413 52.547 15.7203 52.4818 15.5283 52.3467C7.89876 46.9634 3.34424 38.1832 3.34424 28.8584C3.34424 13.02 16.2293 0.133789 32.0689 0.133789C47.9073 0.133789 60.7935 13.0188 60.7935 28.8584C60.7935 31.9753 60.2973 35.0398 59.3197 37.9665C59.1271 38.5408 58.5074 38.8514 57.9307 38.6594C57.3564 38.4669 57.0454 37.8457 57.2379 37.2704C58.1402 34.5684 58.5982 31.7383 58.5982 28.8584C58.5978 14.2302 46.6965 2.32967 32.0683 2.32967C17.4402 2.32967 5.53962 14.2302 5.53962 28.8584C5.53962 37.4707 9.74717 45.5801 16.7938 50.553C17.2891 50.9027 17.4071 51.5872 17.058 52.0825C16.8443 52.3862 16.5048 52.5475 16.1602 52.5475L16.16 52.547Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M64.0308 44.7996C63.904 44.5377 63.6789 44.3355 63.4041 44.2395L57.2324 42.0829C56.6594 41.883 56.0337 42.1853 55.8338 42.7569C55.6339 43.3294 55.9353 43.9555 56.5078 44.1555L60.147 45.4272L32.5491 58.211L27.95 52.0062C27.6381 51.5868 27.0725 51.4443 26.5998 51.6672L0.629747 63.91C0.0807065 64.1682 -0.153141 64.8225 0.105065 65.3706C0.292525 65.768 0.687179 66 1.0993 66C1.25597 66 1.4154 65.9665 1.56702 65.8948L26.7231 54.0366L31.3185 60.2359C31.6286 60.654 32.19 60.7978 32.6614 60.5782L61.1414 47.3873L59.849 51.0845C59.6492 51.657 59.9506 52.2832 60.5231 52.4831C60.643 52.5254 60.7652 52.5447 60.8851 52.5447C61.339 52.5447 61.7631 52.2617 61.9216 51.8091L64.0782 45.6383C64.1738 45.3635 64.1568 45.0617 64.0305 44.7993L64.0308 44.7996Z"
                                                fill="#7215FA"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="sc-mb-0"><Link to="#">Live</Link></h4>
                                    <div className="description sc-mb-25">
                                        •	Kontinuierliche Verfügbarkeit des Prozesses in Echtzeit<br/>
                                        •	Systembasierte digitale Entscheidungsvorbereitung<br/>
                                        •	24/7 Zugriff für den Kunden<br/>
                                        •	Prozess-Transparenz und Live-Tracking<br/>
                                        •	Digitales Kauferlebnis
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom delay={200}>
                            <div className="col">
                                <div className="sc-service-content-box text-center sc-md-mb-30">
                                    <div className="service-icon">
                                        <svg className="sc-mb-25" width="47" height="56" viewBox="0 0 57 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.00261451 13.1124C0.808092 35.9008 9.25289 52.9443 25.819 65.2161C26.5228 65.7398 27.3659 66 28.2088 66C29.053 66 29.8961 65.7398 30.6015 65.2161C47.1656 52.9443 55.6103 35.8993 56.4165 13.1124C56.4888 11.0347 54.9459 9.26836 52.8266 9.00261C45.5961 8.10266 38.217 5.34428 30.273 0.572202C28.9996 -0.190734 27.419 -0.190734 26.1455 0.572202H26.1466C18.2018 5.34485 10.8243 8.10327 3.59303 9.00318C1.47104 9.26836 -0.0719442 11.0345 0.00258862 13.1124H0.00261451ZM27.5048 2.83619H27.5059C27.7225 2.7047 27.965 2.64007 28.2086 2.64007C28.4523 2.64007 28.6948 2.70471 28.9125 2.83619C37.1805 7.8016 44.8953 10.6761 52.4985 11.6224C53.2526 11.718 53.8029 12.3185 53.7785 13.0196C53.0012 34.9509 44.9062 51.3326 29.03 63.0966C28.5543 63.4474 27.8659 63.4474 27.3903 63.0966C11.5121 51.3326 3.41811 34.9509 2.64026 13.0196C2.61595 12.329 3.1778 11.7152 3.9203 11.6224C11.5249 10.6761 19.2398 7.8016 27.5049 2.83619H27.5048Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M19.6003 46.0582H36.8787C39.32 46.0582 41.3067 44.0705 41.3067 41.6293V28.6095C41.3067 26.1815 39.3411 24.2053 36.9174 24.1843V21.2077C36.9174 16.4229 33.0243 12.5298 28.2395 12.5298C23.453 12.5298 19.5605 16.4229 19.5605 21.2077V24.1843C17.1374 24.2064 15.1724 26.1815 15.1724 28.6095V41.6293C15.1724 44.0706 17.159 46.0582 19.6003 46.0582ZM22.2002 21.2078C22.2002 17.8793 24.91 15.1701 28.2396 15.1701C31.5693 15.1701 34.2774 17.8799 34.2774 21.2078V24.1778H22.2001L22.2002 21.2078ZM17.8127 28.6096C17.8127 27.6224 18.6143 26.8203 19.6005 26.8203H36.8788C37.8649 26.8203 38.6666 27.6219 38.6666 28.6096V41.6294C38.6666 42.6167 37.865 43.4188 36.8788 43.4188H19.6005C18.6144 43.4188 17.8127 42.6171 17.8127 41.6294V28.6096Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M26.9067 37.967V39.1957C26.9067 39.9255 27.4973 40.5155 28.2265 40.5155C28.9563 40.5155 29.5463 39.9249 29.5463 39.1957V37.9692C31.2412 37.4102 32.4766 35.829 32.4766 33.949C32.4766 31.6209 30.5701 29.7261 28.2265 29.7261C25.8973 29.7261 24.0024 31.6209 24.0024 33.949C24.0024 35.8262 25.2261 37.4052 26.9067 37.967V37.967ZM28.227 32.3662C29.1154 32.3662 29.8369 33.075 29.8369 33.9489C29.8369 34.8384 29.1149 35.5605 28.227 35.5605C27.3531 35.5605 26.6426 34.8384 26.6426 33.9489C26.6426 33.0622 27.3387 32.3662 28.227 32.3662Z"
                                                fill="#7215FA"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="sc-mb-0"><Link to="#">NAHTLOS</Link></h4>
                                    <div className="description sc-mb-25">
                                        •	Nahtlose Interaktion unterschiedlicher Systeme und Kanäle<br/>
                                        •	All Data in One System mit einheitlichem Datenstrukturmodell<br/>
                                        •	Direkter Zugriff durch den Kunden auf Daten, Datenquellen und Systeme<br/>
                                        •	Digital Analytics <br/>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom delay={300}>
                            <div className="col">
                                <div className="sc-service-content-box text-center sc-md-mb-30">
                                    <div className="service-icon">
                                        <svg className="sc-mb-30" width="65" height="50" viewBox="0 0 83 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.75526 37.1969C8.46482 37.189 8.1835 37.2984 7.9751 37.5007C7.7667 37.7031 7.64941 37.9813 7.64941 38.2718C7.64941 38.5622 7.76668 38.8399 7.9751 39.0422C8.1835 39.2446 8.46482 39.3545 8.75526 39.346H20.2737C20.5641 39.3545 20.8454 39.2445 21.0538 39.0422C21.2622 38.8399 21.3795 38.5622 21.3795 38.2718C21.3795 37.9813 21.2622 37.7031 21.0538 37.5007C20.8454 37.2984 20.5641 37.189 20.2737 37.1969H8.75526Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M32.87 30.5909C32.87 30.3054 32.7569 30.0319 32.5546 29.8302C32.3529 29.6285 32.0789 29.5155 31.7933 29.5161H8.75526C8.46482 29.5076 8.1835 29.6176 7.9751 29.8199C7.7667 30.0222 7.64941 30.2999 7.64941 30.5903C7.64941 30.8808 7.76668 31.159 7.9751 31.3614C8.18351 31.5637 8.46482 31.6731 8.75526 31.6652H31.7933C32.0789 31.6652 32.3523 31.5528 32.5546 31.351C32.7563 31.1493 32.87 30.8759 32.87 30.5909Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M37.5559 29.5163C37.2655 29.5078 36.9842 29.6178 36.7764 29.8201C36.568 30.0225 36.4507 30.3001 36.4507 30.5906C36.4507 30.881 36.5679 31.1593 36.7764 31.3616C36.9842 31.5639 37.2655 31.6733 37.5559 31.6654H45.2373C45.8188 31.6484 46.2811 31.1726 46.2811 30.5905C46.2811 30.0091 45.8188 29.5327 45.2373 29.5163L37.5559 29.5163Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M46.6449 48.7184H5.87454C4.88657 48.7165 3.93985 48.3234 3.24175 47.6253C2.54301 46.9265 2.1505 45.9793 2.14929 44.9919V14.3849H72.0085L72.0079 23.1077C72 23.3981 72.1093 23.6794 72.3117 23.8872C72.514 24.0956 72.7923 24.2129 73.0827 24.2129C73.3731 24.2129 73.6508 24.0957 73.8531 23.8872C74.0555 23.6794 74.1654 23.3981 74.1569 23.1077V6.59018C74.1551 5.03222 73.5354 3.53944 72.4344 2.43786C71.3334 1.33688 69.8399 0.717199 68.2827 0.715332H5.87423C4.31689 0.717155 2.82411 1.33691 1.72253 2.43786C0.62155 3.53944 0.00186656 5.03238 0 6.59018V44.9931C0.00182285 46.5505 0.621581 48.0439 1.72253 49.1448C2.8235 50.2464 4.31705 50.8661 5.87423 50.868H46.6446C47.2261 50.8516 47.6891 50.3752 47.6891 49.7937C47.6891 49.2122 47.2261 48.7359 46.6446 48.7195L46.6449 48.7184ZM5.87454 2.86317H68.2784C69.2657 2.86438 70.213 3.25751 70.9111 3.95564C71.6099 4.65438 72.0024 5.60101 72.0036 6.58904V12.2355L2.1491 12.2349V6.58906C2.15031 5.60109 2.54282 4.65437 3.24156 3.95565C3.93971 3.25751 4.88694 2.8644 5.87435 2.86318L5.87454 2.86317Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M65.5561 24.8948C60.9954 24.8948 56.6218 26.7066 53.3973 29.9312C50.1728 33.1557 48.3608 37.5294 48.3608 42.0894C48.3608 46.65 50.1726 51.0237 53.3973 54.2482C56.6218 57.4726 60.9954 59.2846 65.5561 59.2846C70.1161 59.2846 74.4897 57.4728 77.7142 54.2482C80.9387 51.0237 82.7507 46.65 82.7507 42.0894C82.7452 37.5311 80.9322 33.1607 77.7088 29.9367C74.4848 26.7133 70.1144 24.9002 65.5561 24.8948ZM65.5561 57.135C61.5654 57.135 57.7386 55.5498 54.917 52.728C52.0954 49.9062 50.51 46.0796 50.51 42.0889C50.51 38.0988 52.0952 34.2714 54.917 31.4503C57.7388 28.6285 61.5654 27.0433 65.5561 27.0433C69.5462 27.0433 73.3735 28.6285 76.1952 31.4503C79.0163 34.2714 80.6015 38.0987 80.6015 42.0889C80.5972 46.0779 79.0102 49.9027 76.1897 52.7232C73.3692 55.5437 69.5449 57.1301 65.5561 57.135Z"
                                                fill="#7215FA"
                                            />
                                            <path
                                                d="M74.153 41.0157H66.6284V33.4923C66.612 32.9109 66.1356 32.4485 65.5541 32.4485C64.9727 32.4485 64.4963 32.9109 64.4799 33.4923V41.0157H56.9566C56.3751 41.0327 55.9121 41.5084 55.9121 42.0905C55.9121 42.672 56.3751 43.1484 56.9566 43.1648H64.4799V50.6887C64.4963 51.2702 64.9727 51.7332 65.5541 51.7332C66.1356 51.7332 66.612 51.2702 66.6284 50.6887V43.1648H74.153C74.7344 43.1484 75.1974 42.672 75.1974 42.0905C75.1974 41.5084 74.7344 41.0327 74.153 41.0157Z"
                                                fill="#7215FA"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="sc-mb-0"><Link to="#">AUTOMATISIERT</Link></h4>
                                    <div className="description sc-mb-25">
                                        •	Klare technisch getriggerte Workflows ohne Sackgassen<br/>
                                        •	Reduktion von menschlichen Interaktionsprozessen<br/>
                                        •	Jederzeitige Selbstkonvertierung durch den Kunden möglich<br/>
                                        •	Minimale Durchlaufzeiten und maximale Skalierbarkeit<br/>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home3Services;